import React from 'react';
import PropTypes from 'prop-types';

function SvgMobileApp({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="970.154"
      height="753.564"
      data-name="Layer 1"
      viewBox="0 0 970.154 753.564"
      className={className}
    >
      <path
        fill="#f2f2f2"
        d="M809.692 503.9L617.028 263.142a64.686 64.686 0 01-31.158 15.733c-36.271 7.144-71.693-17.625-79.117-55.323-5.249-26.647 4.911-52.608 24.122-68.068l-65.693-82.092L90.923 393.254l344.51 430.508z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M82.154 752.564L970.154 752.564"
      ></path>
      <circle
        cx="619.429"
        cy="56.916"
        r="14.756"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
      <circle
        cx="814.429"
        cy="485.916"
        r="14.756"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
      <circle
        cx="170.696"
        cy="93.916"
        r="14.756"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
      <circle cx="479.377" cy="139.729" r="54.315" fill="#f2f2f2"></circle>
      <path
        fill="#3f3d56"
        d="M247.807 182.772h240.616a51.15 51.15 0 0149.898 39.9L659.899 761.94a41.34 41.34 0 01-38.294 50.382l-247.354 12.187a54.615 54.615 0 01-56.43-44.827l-68.967-381.268-5.131 1.466-13.196-72.265 5.281-1.32-19.25-106.426a31.493 31.493 0 0131.25-37.097z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        className="fill-current text-theme-one"
        d="M456.746 194.397h28.087a35.349 35.349 0 0134.536 27.813l116.699 534.868a21.903 21.903 0 01-19.968 26.525l-246.554 16.16a28.367 28.367 0 01-29.773-23.278l-99.579-552.838a24.846 24.846 0 0124.452-29.25h26.645a11.726 11.726 0 0111.323 8.677 10.352 10.352 0 009.996 7.661h126.493a17.695 17.695 0 0017.643-16.338z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path fill="#2f2e41" d="M189.246 259.25H444.371V260.507H189.246z"></path>
      <path
        fill="#2f2e41"
        d="M196.787 300.095H454.42600000000004V301.35200000000003H196.787z"
      ></path>
      <path
        fill="#2f2e41"
        d="M205.357 257.26L211.154 249.531 210.149 248.777 205.139 255.457 185.833 239.661 185.038 240.635 205.357 257.26z"
      ></path>
      <path
        fill="#2f2e41"
        d="M238.662 439.492L244.459 431.763 243.454 431.01 238.443 437.689 219.137 421.894 218.342 422.867 238.662 439.492z"
      ></path>
      <path
        fill="#2f2e41"
        d="M306.532 515.817H573.6569999999999V517.074H306.532z"
        transform="rotate(-1.213 -3071.502 4773.83)"
      ></path>
      <path
        fill="#2f2e41"
        d="M309.667 553.206H581.8309999999999V554.463H309.667z"
        transform="rotate(-1.323 -2778.025 4454.647)"
      ></path>
      <path
        d="M414.958 657.204a59.697 59.697 0 00119.394 0c0-.747-.013-1.489-.044-2.23a59.696 59.696 0 00-119.35 2.23z"
        opacity="0.1"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        d="M420.614 680.455c0 .747.012 1.489.044 2.23a59.704 59.704 0 00113.694-25.48c0-.748-.013-1.49-.044-2.231a59.704 59.704 0 00-113.694 25.48z"
        opacity="0.1"
        transform="translate(-90.923 -73.392)"
      ></path>
      <circle cx="692.953" cy="234.917" r="36.8" fill="#2f2e41"></circle>
      <path
        fill="#9f616a"
        d="M732.654 696.064L739.654 726.064 722.654 726.064 715.654 692.064 732.654 696.064z"
      ></path>
      <path
        fill="#9f616a"
        d="M650.654 696.064L650.654 724.064 633.654 726.064 633.654 696.064 650.654 696.064z"
      ></path>
      <path
        fill="#9f616a"
        d="M828.577 451.456s-2 43-4 46-20 46-20 46l-9-28s14-23 12-36-.74-28.997-.74-28.997z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <circle cx="680.654" cy="246.064" r="28" fill="#9f616a"></circle>
      <path
        fill="#9f616a"
        d="M794.577 322.456s-1 40 6 46-40 1-40 1 10-28 2-34 32-13 32-13z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        fill="#2f2e41"
        d="M757.577 367.456s6-11 14-10 34 3 35 5 4 9 6 11 17 5 18 17-36 85-36 85 5 17 4 21 5 16 4 21 15 35 7 74v63s27 109 19 117-35 3-40 0-26-154-26-154l-10-51-1 112s2 94-5 97-34 4-36-2c-1.56-4.68-9.203-129.189-12.491-183.766a203.905 203.905 0 014.403-56.176c4.162-18.878 10.204-41.056 17.088-50.058 13-17 21-71 21-71l-17-31s10-10 16-10 18-4 18-4z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        fill="#2f2e41"
        d="M824.577 383.456l5.273 3.59s3.727 64.41.727 68.41-23.857 2.048-25.429-1.476 19.429-70.524 19.429-70.524z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        fill="#9f616a"
        d="M735.4 442.02c2.927 20.306 5.492 38.383-9.475 54.737a280.862 280.862 0 01-50.533 43.772c-1.836 4.862-28.785 40.572-30.156 26.328s7.94-25.411 21.171-37.456 31.743-38.18 42.187-52.521 6.145-23.283 5.782-35.261 16.917.546 21.024.4z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <path
        fill="#2f2e41"
        d="M724.577 781.255s-1 11.201-4 14.201-11 7-10 18 7.5 11.5 7.5 11.5l-.5-2.5 3 1 .5 1.5h28s.5-10.5-.5-15.5-1-29-4-28-11 2-13 6-8 2.599-7-6.2zM826.577 781.255s1 11.201 4 14.201 11 7 10 18-7.5 11.5-7.5 11.5l.5-2.5-3 1-.5 1.5h-28s-.5-10.5.5-15.5 1-29 4-28 11 2 13 6 8 2.599 7-6.2zM737.577 378.456l-14 3s0 22-4 31-11 30-8 31 31 15 33 7 7-72-7-72z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <circle cx="732.82" cy="186.464" r="36.8" fill="#2f2e41"></circle>
      <path
        fill="#2f2e41"
        d="M855.485 290.328a36.802 36.802 0 01-56.829-19.323 36.802 36.802 0 1071.787-15.09 36.788 36.788 0 01-14.958 34.413z"
        transform="translate(-90.923 -73.392)"
      ></path>
      <circle cx="702.767" cy="241.05" r="26.987" fill="#2f2e41"></circle>
      <ellipse cx="680.074" cy="230.93" fill="#2f2e41" rx="23.92" ry="18.707"></ellipse>
      <ellipse cx="673.693" cy="250.099" fill="#a0616a" rx="4.54" ry="8.032"></ellipse>
    </svg>
  );
}
SvgMobileApp.propTypes = {
  className: PropTypes.string
};
export default SvgMobileApp;
