import React from 'react';
import PropTypes from 'prop-types';

function SvgScaleUp({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="649.675"
      height="516.232"
      data-name="Layer 1"
      viewBox="0 0 649.675 516.232"
      className={className}
    >
      <path
        fill="#f2f2f2"
        d="M759.796 701.915c-8.993-7.599-14.455-19.602-13.022-31.288s10.304-22.428 21.813-24.91 24.627 4.388 28.123 15.63c1.924-21.674 4.14-44.257 15.664-62.715 10.434-16.713 28.507-28.672 48.093-30.811s40.208 5.94 52.424 21.4 15.206 37.934 6.65 55.682c-6.302 13.075-17.913 22.805-30.079 30.722A194.13 194.13 0 01756.69 704.67"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#fff"
        d="M893.522 574.21a317.624 317.624 0 00-44.264 43.954 318.55 318.55 0 00-49.856 83.314c-.897 2.2 2.675 3.157 3.563.982a316.758 316.758 0 0193.17-125.638c1.844-1.502-.784-4.102-2.613-2.613z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        className="fill-current text-theme-one"
        d="M434 707.116h-51a6.507 6.507 0 01-6.5-6.5v-106a6.507 6.507 0 016.5-6.5h51a6.507 6.507 0 016.5 6.5v106a6.507 6.507 0 01-6.5 6.5z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#3f3d56"
        d="M555 708.116h-51a7.508 7.508 0 01-7.5-7.5v-206a7.508 7.508 0 017.5-7.5h51a7.508 7.508 0 017.5 7.5v206a7.508 7.508 0 01-7.5 7.5zm-51-219a5.506 5.506 0 00-5.5 5.5v206a5.506 5.506 0 005.5 5.5h51a5.506 5.506 0 005.5-5.5v-206a5.506 5.506 0 00-5.5-5.5zM676 708.116h-51a7.508 7.508 0 01-7.5-7.5v-337a7.508 7.508 0 017.5-7.5h51a7.508 7.508 0 017.5 7.5v337a7.508 7.508 0 01-7.5 7.5zm-51-350a5.506 5.506 0 00-5.5 5.5v337a5.506 5.506 0 005.5 5.5h51a5.506 5.506 0 005.5-5.5v-337a5.506 5.506 0 00-5.5-5.5z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        className="fill-current text-theme-one"
        d="M798.13 707.616h-51a6.508 6.508 0 01-6.5-6.5v-475a6.508 6.508 0 016.5-6.5h51a6.508 6.508 0 016.5 6.5v475a6.508 6.508 0 01-6.5 6.5z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#a0616a"
        d="M480.942 414.242a10.056 10.056 0 00-10.482-11.308l-17.777-32.61-4 14 12.18 29.886a10.11 10.11 0 0020.079.032z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#a0616a"
        d="M68.816 351.113L78.717 358.342 111.312 323.589 96.698 312.92 68.816 351.113z"
      ></path>
      <path
        fill="#2f2e41"
        d="M335.827 548.547h38.53v14.887h-23.643a14.887 14.887 0 01-14.887-14.887z"
        transform="rotate(-143.869 186.216 504.926)"
      ></path>
      <path
        fill="#a0616a"
        d="M121.201 389.377L133.461 389.376 139.293 342.088 121.199 342.089 121.201 389.377z"
      ></path>
      <path
        fill="#2f2e41"
        d="M393.737 577.758h38.53v14.886h-23.643a14.887 14.887 0 01-14.887-14.886z"
        transform="rotate(179.997 275.423 489.256)"
      ></path>
      <path
        fill="#2f2e41"
        d="M433.183 396.825s6 58-9 98l-11 76h-19l2-90-7-80s14-36 44-4z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#2f2e41"
        d="M93.021 208.94L113.021 277.94 74.021 339.94 93.521 356.44 138.021 286.94 125.021 201.94 93.021 208.94z"
      ></path>
      <circle cx="144.025" cy="29.653" r="24.561" fill="#a0616a"></circle>
      <path
        fill="#ccc"
        d="M398.683 255.325s15-10 32 12l4.5 138.5s-13-18-40-1-33-2-33-2-19-143 36.5-147.5z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#ccc"
        d="M429.473 266.976a17.506 17.506 0 0116.537 16.56l3.173 60.289 21 57-12 10-39-68-7.985-55.893a17.506 17.506 0 0118.275-19.957z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#2f2e41"
        d="M401.14 239.96c4.015 4.278 11.472 1.982 11.995-3.862a7.06 7.06 0 00-.008-1.363c-.27-2.588-1.766-4.938-1.408-7.67a4.023 4.023 0 01.736-1.884c3.2-4.284 10.711 1.917 13.73-1.962 1.852-2.379-.324-6.123 1.097-8.781 1.875-3.509 7.43-1.778 10.913-3.7 3.876-2.137 3.644-8.084 1.093-11.7-3.111-4.412-8.567-6.765-13.954-7.105s-10.737 1.117-15.767 3.078c-5.714 2.227-11.381 5.304-14.898 10.33-4.277 6.11-4.688 14.325-2.55 21.47 1.302 4.347 5.742 9.657 9.021 13.15z"
        transform="translate(-275.162 -191.884)"
      ></path>
      <path
        fill="#3f3d56"
        d="M923.647 707.691H276.353a1.19 1.19 0 010-2.38h647.294a1.19 1.19 0 010 2.38z"
        transform="translate(-275.162 -191.884)"
      ></path>
    </svg>
  );
}

SvgScaleUp.propTypes = {
  className: PropTypes.string
};

export default SvgScaleUp;
